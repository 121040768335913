<template>
  <!-- Uber Eats 授權登入 -->
  <div v-if="getAccessToken == 'noToken'" class="external-platform-wrap" v-loading="loading">
    <div>
      <p class="block-title">Uber Eats 授權</p>
      <p class="gray-hint">登入Uber Eats 帳號後，進行授權設定</p>
    </div>
    <el-button type="primary" size="large" style="width: 120px" @click="toLogin">登入</el-button>
  </div>
  <!-- 已登入後顯示資訊 -->
  <div v-else class="external-platform-wrap" style="padding: 30px 10px 8px" v-loading="loading">
    <p class="block-title gap">Uber Eats 授權</p>
    <div class="top-wrap gap">
      <div class="store-title">門市</div>
      <div class="green-hint">僅限綁定單一分店 ; 如須解除綁定，請先切換至該綁定門市帳號</div>
    </div>
    <div class="info-wrap gap" v-for="(store, index) in getUberEatsStoreList" :key="'store' + index">
      <div>{{ store.name + ' - ' + store.address }}</div>
      <el-button
        v-if="store.is_current_binding == true"
        type="info"
        plain
        @click="toUnbind(store.store_id)"
        style="font-weight: normal; border: none; width: 110px"
      >
        解除綁定
      </el-button>
      <el-button
        v-else-if="store.can_binding == true"
        type="primary"
        style="width: 110px"
        :disabled="isdisabled"
        @click="toBind(store.store_id)"
      >
        綁定
      </el-button>
      <div v-if="store.can_binding == false && store.is_current_binding == false" class="isbinded">
        已綁定：
        {{ store.integrator_store_id + ' ' + store.integrator_store_name }}
      </div>
    </div>
  </div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
import { ref, watch, getCurrentInstance } from 'vue';

export default {
  name: 'external-platform',
  setup() {
    const { proxy } = getCurrentInstance();
    const { getAccessToken, getUberEatsStoreList } = useGetters('externalPlatform', [
      'getAccessToken',
      'getUberEatsStoreList',
    ]);
    const SET_ACCESS_TOKEN = useMutations('externalPlatform', ['SET_ACCESS_TOKEN']);
    const { doGetUberEatsData, doBindUberEats, doUnBindUberEats } = useActions('externalPlatform', [
      'doGetUberEatsData',
      'doBindUberEats',
      'doUnBindUberEats',
    ]);
    const route = useRoute();
    const router = useRouter();
    const loading = ref(false);
    const isdisabled = ref(true);

    // 更新列表資料
    const refreshList = async () => {
      loading.value = true;
      await doGetUberEatsData({ access_token: getAccessToken.value });
      let current_binding_arr = [];
      current_binding_arr = getUberEatsStoreList.value.filter((data) => data.is_current_binding == true);
      if (current_binding_arr.length > 0) isdisabled.value = true;
      else isdisabled.value = false;
      loading.value = false;
    };

    if (getAccessToken.value == 'noToken' && route.query.access_token != undefined) {
      // 登入再更新資料
      SET_ACCESS_TOKEN(route.query.access_token);
      router.push({ path: '/system/platform', replace: true }).then(async () => {
        await refreshList();
      });
    } else SET_ACCESS_TOKEN('noToken');

    const toLogin = () => {
      loading.value = true;
      window.location.href = '/ubereats/gotoAuth';
    };

    const toBind = async (id) => {
      try {
        let res = await doBindUberEats({
          store_id: id,
          access_token: getAccessToken.value,
        });
        if (res.message == 'success')
          await refreshList().then(() => {
            proxy.$message({
              type: 'success',
              message: `綁定成功`,
            });
          });
      } catch (e) {
        e;
        // console.log('error-', e);
      }
    };

    const toUnbind = async (id) => {
      try {
        let res = await doUnBindUberEats({
          store_id: id,
          access_token: getAccessToken.value,
        });
        if (res.message == 'success')
          await refreshList().then(() => {
            proxy.$message({
              type: 'success',
              message: `已解除綁定`,
            });
          });
      } catch (e) {
        e;
        // console.log('error-', e);
      }
    };

    //離開清空vuex
    const RESET = useMutations('externalPlatform', ['RESET']);
    watch(
      () => route.path,
      () => {
        if (!route.path.includes('/system/platform')) RESET();
      }
    );

    return {
      loading,
      getAccessToken,
      getUberEatsStoreList,
      isdisabled,
      toLogin,
      toBind,
      toUnbind,
    };
  },
};
</script>
<style lang="scss" scoped>
.external-platform-wrap {
  @include white-bg;
  height: auto;
  padding: 30px 40px;
  @include flex-between-center;
  flex-wrap: wrap;
}
.block-title {
  font-size: 1.6rem;
  font-weight: 500;
}
.gray-hint {
  font-size: 1.4rem;
  color: #b0afb0;
  margin-top: 8px;
}
.store-title {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  margin-right: 24px;
  font-weight: 500;
}
.top-wrap {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 16px;
}
.info-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  min-height: 76px;
  border-top: 1px solid #ebebeb;
  font-size: 14px;
}
.isbinded {
  color: $color-text-light;
}
.gap {
  padding-right: 6px;
  padding-left: 30px;
}
</style>
